import { createSlice } from '@reduxjs/toolkit';

import Api from 'api';
import { getSportsDataEndpoint } from 'utils/urlHandlers';

import { sseMarketsConnect } from './sse';

// interface TopMenu {
//   id: number;
//   name: string;
//   itemType: string; // "Sport"
//   path: string;
//   identifier: number; // "Sport Identifier from Betfair"
// }

const initialState = {
  events: {},
  markets: {},
  topMenu: [],
  liquidityThreshold: 0,
  enforceLiquidityThreshold: true,
  sportsData: [],
  hasSportsData: true,
  leftNavigation: [],
  leftNavIsLoading: false,
  activeSportId: 0,
  currentNavState: {},
  postLoginLoad: false,
  currentMenuEndpoint: '',
  currentTopMenuEndpoint: '',
  favorites: '',
  processingFavorites: true,
  navMarkets: {},
  subscribeMarkets: [],
};

export const sportsSlice = createSlice({
  name: 'sports',
  initialState,
  reducers: {
    eventsLoaded: (state, action) => {
      state.events = action.payload;
    },
    marketsLoaded: (state, action) => {
      state.markets = action.payload;
    },
    eventDetailsLoaded: (state, action) => {
      state.eventDetails = action.payload;
    },
    topMenuLoaded: (state, action) => {
      state.topMenu = action.payload;
    },
    addEvent: (state, action) => {
      state.events = action.payload;
    },
    addMarket: (state, action) => {
      state.markets = action.payload;
    },
    setliquidityThreshold: (state, action) => {
      state.liquidityThreshold = action.payload;
    },
    setEnforceLiquidityThreshold: (state, action) => {
      state.enforceLiquidityThreshold = action.payload;
    },
    setSportsData: (state, action) => {
      state.sportsData = action.payload;
    },
    setHasSportsData: (state, action) => {
      state.hasSportsData = action.payload;
    },
    leftNavLoaded: (state, action) => {
      state.leftNavigation = action.payload;
    },
    setLeftNavIsLoading: (state, action) => {
      state.leftNavIsLoading = action.payload;
    },
    setActiveSportId: (state, action) => {
      state.activeSportId = action.payload;
    },
    setCurrentNavState: (state, action) => {
      state.currentNavState = action.payload;
    },
    setPostLogin: (state, action) => {
      state.postLoginLoad = action.payload;
    },
    setCurrentMenuEndpoint: (state, action) => {
      state.currentMenuEndpoint = action.payload;
    },
    setCurrentTopMenuEndpoint: (state, action) => {
      state.currentTopMenuEndpoint = action.payload;
    },
    favoritesLoaded: (state, action) => {
      state.favorites = action.payload;
    },
    setProcessingFavorites: (state, action) => {
      state.processingFavorites = action.payload;
    },
    prepareMarketForRemoval: (state, action) => {
      if (state.markets[action.payload.identifier]) {
        state.markets[action.payload.identifier].awaitRemoval = true;
      }
    },
    removeMarketFromFavorites: (state, action) => {
      const marketIdentifier = action.payload.identifier;

      // Remove the market from the events within the favorites
      if (state.favorites) {
        state.favorites = state.favorites
          .map((sport) => ({
            ...sport,
            events: sport.events
              .map((event) => ({
                ...event,
                markets: event.markets.filter(
                  (market) => market.identifier !== marketIdentifier
                ),
              }))
              .filter((event) => event.markets.length > 0),
          }))
          // Filter out sports with no events remaining
          .filter((sport) => sport.events.length > 0);
      }
    },
    removeMarket: (state, action) => {
      const marketIdentifier = action.payload.identifier;

      // state.markets = Object.fromEntries(filteredKvps);
      delete state.markets[marketIdentifier];
    },
    removeEvents: (state, action) => {
      const { id } = action.payload;
      const eventsObject = JSON.parse(JSON.stringify(state.events));
      const kvps = Object.entries(eventsObject);

      const filteredKvps = kvps.filter(([, event]) => event.id !== id);

      state.events = Object.fromEntries(filteredKvps);
      const sportsData = JSON.parse(JSON.stringify(state.sportsData));

      const updatedSportsData = sportsData.map((sport) => ({
        ...sport,
        events: sport.events.filter((event) => event.id !== id),
        totalItems: sport.totalItems - 1,
      }));

      state.sportsData = updatedSportsData;
    },
    setSubscribeMarkets: (state, action) => {
      state.subscribeMarkets = action.payload;
    },
    setNavMarkets: (state, action) => {
      state.navMarkets = action.payload;
    },
  },
});

export const {
  eventsLoaded,
  marketsLoaded,
  eventDetailsLoaded,
  topMenuLoaded,
  addEvent,
  addMarket,
  setliquidityThreshold,
  setEnforceLiquidityThreshold,
  setSportsData,
  setHasSportsData,
  leftNavLoaded,
  setLeftNavIsLoading,
  setActiveSportId,
  setCurrentNavState,
  setPostLogin,
  setCurrentMenuEndpoint,
  setCurrentTopMenuEndpoint,
  favoritesLoaded,
  setProcessingFavorites,
  prepareMarketForRemoval,
  removeMarketFromFavorites,
  removeMarket,
  removeEvents,
  setNavMarkets,
  setSubscribeMarkets,
} = sportsSlice.actions;

export const sportsReducer = sportsSlice.reducer;

export const fetchTopMenu = (route) => async (dispatch) => {
  try {
    const {
      data: { menu },
    } = await Api.get(route);

    dispatch(setCurrentTopMenuEndpoint(route));
    dispatch(topMenuLoaded(menu));
  } catch (err) {
    console.error(err);
  }
};

export const fetchMarkets = (pathname) => async (dispatch, getState) => {
  const route = pathname;

  if (!route) {
    dispatch(marketsLoaded({}));
    dispatch(eventDetailsLoaded({ name: '', id: '', openDate: '' }));
    return;
  }

  const { data: res } = await Api.get(route);

  const { markets, eventDetails, liquidityThreshold } = res;

  if (Object.hasOwn(res, 'enforceLiquidityThreshold')) {
    dispatch(setEnforceLiquidityThreshold(res.enforceLiquidityThreshold));
  }

  if (markets) {
    const eventsIds = [];
    const sportsData = {};
    markets.forEach((item) => {
      eventsIds.push(item.identifier);
      const market = item;
      // const mselections = {};
      // item.selections.forEach((selection) => {
      //   mselections[selection.identifier] = selection;
      // });
      // market.selections = mselections;
      sportsData[item.identifier] = market;
    });
    dispatch(marketsLoaded(sportsData));
    dispatch(eventsLoaded({}));

    const sMrkts = [...getState().sports.subscribeMarkets];
    if (!eventsIds.every((item) => sMrkts.includes(item))) {
      dispatch(setSubscribeMarkets([...eventsIds, ...sMrkts]));
      dispatch(sseMarketsConnect('sse-markets', [...eventsIds, ...sMrkts]));
    } else {
      dispatch(sseMarketsConnect('sse-markets', sMrkts));
    }
  } else {
    dispatch(marketsLoaded({}));
  }

  if (eventDetails) {
    if (liquidityThreshold) {
      eventDetails.liquidityThreshold = liquidityThreshold;
    }
    dispatch(eventDetailsLoaded(eventDetails));
  } else {
    dispatch(eventDetailsLoaded({ name: '', id: '', openDate: '' }));
  }
};

export const fetchCashoutMarkets = (cashouts) => (dispatch) => {
  const marketsDummy = {};
  const eventIds = [];
  cashouts.forEach((cashout) => {
    eventIds.push(cashout.marketIdentifier);
    marketsDummy[cashout.marketIdentifier] = {
      id: cashout.marketId,
      identifier: cashout.marketIdentifier,
      inPlay: cashout.inPlay,
      name: cashout.marketName,
      selections: [],
    };
  });

  dispatch(marketsLoaded(marketsDummy));
  dispatch(eventsLoaded({}));
  dispatch(sseMarketsConnect('sse-markets', eventIds));
};

export const fetchSports = (pathname) => async (dispatch) => {
  const route = getSportsDataEndpoint(pathname);
  dispatch(setHasSportsData(true));

  if (route) {
    const { data: res } = await Api.get(route);
    const { sports, liquidityThreshold } = res;
    if (!sports || !sports.length) {
      dispatch(setHasSportsData(false));
    }

    if (Object.hasOwn(res, 'enforceLiquidityThreshold')) {
      dispatch(setEnforceLiquidityThreshold(res.enforceLiquidityThreshold));
    }

    if (sports) {
      const allSportsData = {};
      /* sports = sports.map((sport) => {
        const newSport = sport;
        newSport.events = sport.events.map((event) => ({
          ...event,
          sportName: sport.name,
        }));
        return newSport;
      }); */
      sports.forEach((sport) => {
        sport.events.forEach((event) => {
          const mappedEvent = {
            ...event,
            sportName: sport.name,
            sportId: sport.id,
          };
          // const eselections = {};
          // event.selections.forEach((selection) => {
          //   eselections[selection.identifier] = selection;
          // });
          // mappedEvent.selections = eselections;
          allSportsData[event.topMarketIdentifier] = mappedEvent;
        });
      });
      const eventsIds = Object.keys(allSportsData);
      if (!eventsIds.length) {
        dispatch(setHasSportsData(false));
      }

      dispatch(eventsLoaded(allSportsData));
      dispatch(marketsLoaded({}));
      dispatch(sseMarketsConnect('sse-markets', eventsIds));
      dispatch(setliquidityThreshold(liquidityThreshold));
      dispatch(setSportsData(sports));
    } else {
      dispatch(eventsLoaded({}));
    }
  } else {
    dispatch(eventsLoaded({}));
  }
};

export const fetchLeftNav = (pathname) => async (dispatch, getState) => {
  dispatch(leftNavLoaded([]));
  dispatch(setLeftNavIsLoading(true));
  dispatch(setCurrentMenuEndpoint(pathname));
  const {
    data: { menu },
  } = await Api.get(pathname);

  dispatch(setNavMarkets({}));
  const marketItems = menu?.filter?.((item) => item.itemType === 'Market');
  if (Array.isArray(marketItems) && marketItems.length) {
    const navData = {};
    marketItems.forEach((item) => {
      navData[item.identifier] = item;
    });
    dispatch(setNavMarkets(navData));
  }

  const marketIdentifiers = marketItems?.map?.((i) => i.identifier);

  if (Array.isArray(marketIdentifiers) && marketIdentifiers.length) {
    if (
      !marketIdentifiers.some((item) =>
        getState().sports.subscribeMarkets.includes(item)
      )
    ) {
      dispatch(setSubscribeMarkets(marketIdentifiers));
      dispatch(sseMarketsConnect('sse-markets', marketIdentifiers));
    }
  }

  dispatch(leftNavLoaded(menu));
  dispatch(setLeftNavIsLoading(false));
};

export const fetchFavorites = (silent) => async (dispatch) => {
  if (!silent) {
    dispatch(setProcessingFavorites(true));
  }
  const { data } = await Api.get('/Menu/Favorites');

  const { sports, liquidityThreshold } = data;
  if (!sports || !sports.length) {
    dispatch(setHasSportsData(false));
  }

  const marketIds = [];
  const sportsData = {};

  if (sports) {
    sports.forEach((sport) => {
      sport.events?.forEach((event) => {
        event.markets?.forEach((market) => {
          marketIds.push(market.identifier);
          sportsData[market.identifier] = {
            ...market,
            ...{ isFavorite: true },
          };
        });
      });
    });

    if (Object.hasOwn(data, 'enforceLiquidityThreshold')) {
      dispatch(setEnforceLiquidityThreshold(data.enforceLiquidityThreshold));
    }

    dispatch(marketsLoaded(sportsData));
    dispatch(sseMarketsConnect('sse-markets', marketIds));
    dispatch(setliquidityThreshold(liquidityThreshold));
    dispatch(favoritesLoaded(sports));
  } else {
    dispatch(eventsLoaded({}));
  }
  dispatch(setProcessingFavorites(false));
};
