import Api from 'api';
import { addEvent, addMarket, setNavMarkets } from 'slices/sports';
import { sseMarketsConnected, sseMarketsDisconnected } from 'slices/sse';
import { throttle } from 'lodash';
import { sseContractDictionary, translator } from './contractsTransformer';

const DELAY = navigator.userAgent.match(/Android/i) ? 500 : 100;
window.waiting = false;
let queue = {};
let start;

function setStart() {
  start = performance.now();
  window.start = start;
  // console.log(navigator.userAgent);
}

setStart();

const batchUpdate = (store, data) => {
  queue = {};
  // console.log(`%cbatch update ${performance.now() - start}`, 'color: blue');
  const eventsData = store.getState().sports.events;
  const newEventsData = Object.entries(eventsData).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: {
        ...value,
        ...data[key],
      },
    }),
    {}
  );
  const marketsData = store.getState().sports.markets;
  const newMarketsData = Object.entries(marketsData).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: {
        ...value,
        ...data[key],
      },
    }),
    {}
  );
  const navData = store.getState().sports.navMarkets;
  const newNavData = Object.entries(navData).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: {
        ...value,
        ...data[key],
      },
    }),
    {}
  );
  if (Object.keys(newEventsData).length > 0) {
    store.dispatch(addEvent(newEventsData));
  }
  if (Object.keys(newMarketsData).length > 0) {
    store.dispatch(addMarket(newMarketsData));
  }
  if (Object.keys(newNavData).length > 0) {
    store.dispatch(setNavMarkets(newNavData));
  }
};

const throttled = throttle(batchUpdate, DELAY, {
  leading: true,
  trailing: true,
});

export const sseMarkets = () => {
  let socket = null;

  const onOpen = (store) => (event) => {
    setStart();
    store.dispatch(sseMarketsConnected(event.target.url));
  };

  const onClose = (store) => (event) => {
    throttled.cancel();
    store.dispatch(sseMarketsDisconnected(event.target.url));
  };

  const onMessage = (store, data) => {
    // console.log(queue);
    // console.log('on message waiting', window.waiting);
    translator({ data, dictionary: sseContractDictionary });
    // console.log(data);
    // console.log(parsedData);

    if (!window.waiting) {
      // console.log(DELAY);
      setStart();
      window.waiting = true;
    }

    queue[data.MarketIdentifier] = { data };

    throttled(store, { ...queue });
  };

  // eslint-disable-next-line consistent-return
  return (store) => (next) => (action) => {
    const { currencyCode } = store.getState().user;
    switch (action.type) {
      case 'SSE_MARKETS_CONNECT':
        if (socket !== null) {
          socket.close();
        }

        socket = new EventSource(
          `${Api.defaults.baseURL}/${
            action.host
          }?currency=${currencyCode}&markets=${action.eventIds.join(',')}`
        );
        /*
        TotalMatched - display sum near Link to Video
        Status - Open(not disabled)
        Runners Array
          0 - First Team
          1 - Second Team
          2 - Draw

            Prices
              AvailableToBack(blue)[0]
              AvailableToLay(red)[0]
                Size - Sum
                Price - Rate
        */

        socket.onmessage = (e) => onMessage(store, JSON.parse(e.data));
        socket.onclose = onClose(store);
        socket.onopen = onOpen(store);
        break;
      case 'SSE_MARKETS_DISCONNECT':
        if (socket !== null) {
          socket.close();
        }
        break;
      default:
        return next(action);
    }
  };
};

export const sseMarketsMiddleware = sseMarkets();
